import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import PostPreview from "../components/PostPreview"
import ArchivePagination from "../components/ArchivePagination"

const PostListing = (props) => {
  const {
    data: {
      allWpPost: { edges, pageInfo },
    },
    pageContext: { archivePath },
  } = props
  return (
    <Layout
      bodyClass="home blog wp-embed-responsive has-no-pagination showing-comments hide-avatars footer-top-visible customize-support">
      <GatsbySeo
        title="Best offers and news" description="Offers and news"
        canonical={`https://paynplay.app/campaigns/`}
      />
      {edges &&
        edges.map((post, index) => {
          return (
            <PostPreview
              key={index}
              post={post}
              isLast={index === edges.length - 1}
            />
          )
        })}

      <ArchivePagination {...pageInfo} archivePath={archivePath} />
    </Layout>
  )
}


export const query = graphql`
  query ArchivePage(
    $skip: Int!, $limit: Int!
  ) {
    allWpPost(
      limit: $limit
      skip: $skip
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          id
          title
          content
          databaseId
          date (formatString: "DD.MM.YYYY")
          excerpt
          uri
          slug
          featuredImage {
            node {
              id
              mediaItemUrl
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        currentPage
        pageCount
      }
    }
  }
`

export default PostListing